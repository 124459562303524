<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13em;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 1.2em" :to="{ path: '/expert_index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 1.2em;color: black">问题搜索</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  <!--  搜索问题列表-->
    <div>
      <div v-for="(item, index) in question_list" style="width: 95%;margin: auto auto 2% auto;background-color: #FFFFFF;min-height: 5vh">
        <div style="font-weight: bolder;font-size: 1.3em;margin-left: 2%;padding-top: 0.5%;caret-color: transparent;">
          <a href="javascript:void(0);" style="text-decoration: none;color: black" @click="getQuestion(item)">{{ item.questionTitle }}</a>
        </div>
        <div style="float: right;margin-right: 1%;font-size: 0.5em">
          {{ item.questionTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header1";

export default {
  components: {Header},
  name: "search_question",
  data(){
    return{
      question_list_answer: [],
      question_list: []
    }
  },
  created() {
    // console.log(this.$route.params.key)
    this.axios.post('/expertQuestion/getQuesAnsBySearch', (response) => {
      this.question_list_answer = response.obj;
    },{
      question_abstract: localStorage.getItem('key')
    });
    this.axios.post('/expertQuestion/getQuesBySearch', (response) => {
      this.question_list = response.obj;
    },{
      question_abstract: localStorage.getItem('key')
    });

  },
  methods:{
    getQuestion(val){
      this.$router.push({
        name: 'Answer_detail',
        params: {
          key: val.id,
          flag: '1',
          question: val.question,
          title: val.questionTitle,
          question_userid: val.question_userid,
          questionUser: val.questionUser,
          questionTime: val.questionTime
        }
      });
    }
  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
</style>